<template>
  <div class="video-card-container">
    <v-item-group>
      <v-container class="pa-0 pa-sm-2 pa-md-0">
        <v-row>
          <v-col
            v-for="(video, index) in videos" :key="index"
            cols="12"
            xl="3"
            lg="3"
            md="4"
            sm="6"
            xs="12"
          >
            <v-item v-slot:default="{ active, toggle }" @click="setVideo(video)">
              <v-skeleton-loader
                :loading="loading"
                transition="'scale-transition'"
                type="card"
              >
              <!-- inner skeleton -->
              <v-card
                :color="active ? 'primary' : ''"
                class="mx-auto d-flex flex-column ma-sm-1"
                @click="setVideo(video)"
                >
                <v-img
                class="white--text align-end"
                :src="video.thumbnail_large"
                aspect-ratio="1.8"
                >
                <v-card-title  v-if="$vuetify.breakpoint.mdAndUp">
                  <div class="d-inline-block text-truncate overlay-title" style="max-width: 100%;">
                    {{video.title}}
                  </div>
                </v-card-title>
              </v-img>
              <div class="text">
                <v-card-text class="d-flex align-center pa-2">
                  <v-avatar size="40" :style="`background-color:${video.bg}`" dark v-if="Object.hasOwnProperty.call(video,'avatar')">
                    <img :src="video.avatar" alt="">
                  </v-avatar>
                  <v-avatar size="40" color="primary" dark v-else>
                    <strong class="white--text" style="font-size:120%">{{video.channelTitle.charAt(0)}}</strong>
                  </v-avatar>
                  <div class="pl-2">
                    <div class="" v-if="$vuetify.breakpoint.smAndDown">
                      <strong class="grey--text text--darken-1">{{video.title}}</strong> <br>
                      <strong class="primary--text">{{video.channelTitle}}</strong>
                    </div>
                    <div class="" v-else>
                      <strong class="primary--text">{{video.channelTitle}}</strong>
                    </div>
                    <div class="" style="font-size:12.5px">
                      <!-- {{categories[video.categoryId]}} -->
                      <!-- <span class="mx-1 primary--text">|</span> -->
                      <!-- <br> -->
                      {{video.steps.length}} steps
                      <span class="mx-1 primary--text">|</span>
                      {{$getDuration(video.duration)}}
                    </div>
                  </div>

              </v-card-text>
              <v-card-actions v-if="withEdit" class="justify-space-between" style="border-top: 1px solid #ddd">
                <v-btn color="secondary darken-2" text @click="$router.push({name:'create', params: {videoId: video['.key']}})">
                  <v-icon class="mr-1">library_books</v-icon> Edit
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="$router.push({name:'watch', params: {videoId: video['.key']}})">
                  <v-icon>play_arrow</v-icon> Play
                </v-btn>
              </v-card-actions>
            </div>
          </v-card>

              </v-skeleton-loader>
            </v-item>
          </v-col>
        </v-row>

        <v-row v-if="loading">
          <v-col
            v-for="(video, index) in videos" :key="index"
            cols="12"
            md="4"
          >
            <v-item v-slot:default="{ active, toggle }">
              <v-skeleton-loader
                :loading="loading"
                transition="'scale-transition'"
                type="card"
              >
              </v-skeleton-loader>
            </v-item>
          </v-col>
        </v-row>

      </v-container>
    </v-item-group>
  </div>
</template>

<script>

import categoryJSON from "@/components/json/youtube-categories.json"
export default {
  name: "video-cards",
  props: ['videos', 'withEdit', 'loading'],
  components:{categoryJSON},
  data(){
    return{
      categories: categoryJSON,
    }
  },
  methods: {
    setVideo(video){
      let vThis = this;
      if(this.withEdit){
        return false;
        // this.$store.dispatch('setBuildVideo', video).then(data=>{
        //   vThis.$router.push({name:'create', params: {videoId: video['.key']}})
        // })
      }
      else{
        this.$store.dispatch('setVideo', video).then(data=>{
          vThis.$router.push({name:'watch', params: {videoId: video['.key']}})
        })
      }
    }
  },
};

// <v-card-actions v-if="withEdit" class="justify-space-between">
//   <v-btn color="secondary darken-2" text @click="$router.push({name:'create', params: {videoId: video['.key']}})">
//     <v-icon class="mr-1">library_books</v-icon> Edit
//   </v-btn>
//   <v-btn color="primary darken-1" text @click="$router.push({name:'watch', params: {videoId: video['.key']}})">
//     <v-icon>play_arrow</v-icon> Play
//   </v-btn>
//   <v-spacer></v-spacer>
//   <!-- video -->
//   <v-tooltip top>
//     <template v-slot:activator="{ on }">
//       <v-btn v-on="on" icon color="error darken-1" class="ma-0 pa-0 cap" :href="`https://www.youtube.com/watch?v=${video.videoId}`" target="_blank">
//         <v-icon class="ml-1">ondemand_video</v-icon>
//       </v-btn>
//     </template>
//     <span>Youtube Video</span>
//   </v-tooltip>
//   <!-- channel -->
//   <v-tooltip top>
//     <template v-slot:activator="{ on }">
//       <v-btn v-on="on" icon color="error darken-1" class="ma-0 pa-0 cap" :href="`https://www.youtube.com/channel/${video.channelId}`" target="_blank">
//         <v-icon class="ml-1">subscriptions</v-icon>
//       </v-btn>
//     </template>
//     <span>Youtube Channel</span>
//   </v-tooltip>
// </v-card-actions>
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .video-card-container{
    .v-card{
      display: flex;
      flex: 1 0 auto;
      height: 100%;
      .v-card__title{
        font-size: 17px;
        padding: 8px;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.45+100 */
        background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.45) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.45) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.45) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#73000000',GradientType=0 ); /* IE6-9 */
        .overlay-title{
          text-shadow: 1px 1px 3px rgba(0,0,0,.75);
        }
      }
      .text{
        flex:1 0 auto;
      }
      .v-image{
        cursor: pointer;
        &:hover{
          opacity: .9;
        }
      }
      .cap{
        text-transform: capitalize;
      }
    }
  }

</style>
